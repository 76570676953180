import React from 'react';
import { Helmet } from 'react-helmet-async';

import { graphql } from 'gatsby';
import { BlogJsonLd } from 'gatsby-plugin-next-seo/lib/jsonld/blog';

import { Layout } from '@layouts';
import { Section, Auctions, Pagination, ErrorMsg } from '@components';
import { useSeo, useContact } from '@queries';

const AuctionsPage = ({ pageContext, data }) => {
  const { description_auctions } = useSeo();
  const { name } = useContact();

  const auctions = data.allSanityAuction.edges.map(edge => edge.node);

  const {
    pageNumber,
    numberOfPages,
    previousPagePath,
    nextPagePath,
  } = pageContext;

  const title = 'Licytacje';

  const canonical = `https://komornik-olsztyn.pl/licytacje${
    pageNumber ? `/${pageNumber + 1}` : ''
  }`;

  return (
    <>
      <BlogJsonLd
        url={canonical}
        headline={title}
        posts={auctions.map(({ title, images }) => ({
          headline: title,
          image: images.map(image => image.asset.fluid.src),
        }))}
        authorName={name}
        description={description_auctions}
      />
      <Layout
        title={`${title}${pageNumber ? ` - strona nr ${pageNumber + 1}` : ''}`}
        description={`${description_auctions} ${`Strona nr ${pageNumber + 1}`}`}
        canonical={canonical}
      >
        <Helmet>
          {pageNumber > 0 && (
            <link
              rel="prev"
              href={`https://komornik-olsztyn.pl/licytacje${
                pageNumber - 1 ? `/${pageNumber}` : ''
              }`}
            />
          )}
          {pageNumber < numberOfPages - 1 && (
            <link
              rel="next"
              href={`https://komornik-olsztyn.pl/licytacje/${pageNumber + 2}`}
            />
          )}
        </Helmet>
        <Section main heading={title} spaceTop>
          {numberOfPages > 1 && (
            <Pagination
              numOfPages={numberOfPages}
              currentPage={pageNumber}
              prevPageLink={previousPagePath}
              nextPageLink={nextPagePath}
            />
          )}

          {!!auctions.length ? (
            <Auctions auctions={auctions} />
          ) : (
            <ErrorMsg msg="Brak aktualnych licytacji" />
          )}
        </Section>
      </Layout>
    </>
  );
};

export const auctionsPageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allSanityAuction(
      sort: { fields: _createdAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          _createdAt(formatString: "DD.MM.YYYY")
          images {
            asset {
              id
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;

export default AuctionsPage;
